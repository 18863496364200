import { ElNotification } from "element-plus/es";
import i18n from "@/core/plugins/i18n";
import { useI18n } from "vue-i18n";
import { currencies } from "@/core/helpers/config";

// import fa from "locales/fa";
const { t } = i18n.global;
const currency = process.env.VUE_APP_CURRENCY;

const nullValue = "-";
export enum NotifType {
  success = "success",
  error = "error",
  warning = "warning",
}
function padTo2Digits(num) {
  return String(num).padStart(2, "0");
}
const utils = {
  dashFormatNullValueCell(row, column, cellValue, index) {
    return cellValue?.toString().trim() || nullValue;
  },
  dashFormatNullValue(value) {
    return value?.toString().trim() || nullValue;
  },
  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  },
  getPersianDate(e) {
    if (e && e != "0001-01-01T00:00:00") {
      let temp = e;
      temp = temp.slice(0, 10);
      temp = new Date(temp).toLocaleDateString("fa-IR");
      return temp;
    }
    return nullValue;
  },
  getPersianDateTime(e) {
    if (e && e != "0001-01-01T00:00:00") {
      return (
        new Date(e).toLocaleTimeString("fa-IR", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " - " +
        new Date(e).toLocaleDateString("fa-IR")
      );
    }
    return nullValue;
  },
  getGregorianDate(e, full = true) {
    if (!e) return;
    const date = new Date(e);
    const mm = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    return (
      (full
        ? padTo2Digits(date.getHours()) +
          ":" +
          padTo2Digits(date.getMinutes()) +
          " - "
        : "") +
      [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("/")
    );
  },
  persianOrGregorianDate(e, full = true) {
    return this.getGregorianDate(e, full);
  },
  getUTCDate(e) {
    if (e) {
      let temp = e;
      temp = temp.slice(0, 10);
      temp = new Date(temp).toLocaleDateString();
      return temp;
    }
    return undefined;
  },
  getTime(e) {
    if (e) {
      let temp = e;
      temp = temp.slice(11, 16);
      return temp;
    }
    return undefined;
  },
  separatePrice(price, sign = true) {
    if ((price !== null && price >= 0) || (price !== null && price <= 0)) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else return "-";
  },

  newSeparatePrice(price, sign = true) {
    let negativeFlag = false;
    if (price !== null) {
      if (price.toString().indexOf("-") > -1) {
        negativeFlag = true;
      }
      return !negativeFlag
        ? price.toString().indexOf(".") > -1
          ? price
              .toString()
              .substring(0, price.toString().indexOf("."))
              .replace(/[^((\d)|(.))]/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            price.toString().substring(price.toString().indexOf("."))
          : price
              .toString()
              .replace(/[^((\d)|(.))]/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : price.toString().indexOf(".") > -1
        ? "-" +
          price
            .toString()
            .substring(0, price.toString().indexOf("."))
            .replace(/[^((\d)|(.))]/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          price.toString().substring(price.toString().indexOf("."))
        : "-" +
          price
            .toString()

            .replace(/[^((\d)|(.))]/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else return "-";
  },
  downloadLink(data, name) {
    const fileLink = document.createElement("a");
    fileLink.href =
      process.env.VUE_APP_API_BASE_URL + "/blob/export/excel/" + name;

    fileLink.setAttribute("download", name);
    document.body.appendChild(fileLink);

    fileLink.click();
  },
  downloadJson(value, name) {
    const link = document.createElement("a");
    const file = new Blob([JSON.stringify(JSON.parse(value), null, 2)], {
      type: "text/json",
    });
    link.href = URL.createObjectURL(file);
    link.download = `${name}.json`;
    link.click();
    URL.revokeObjectURL(link.href);
  },
  // resizeHandler() {
  //   new ResizeObserver(() => console.log("resize observed!"));
  // },
  deepCopy<T>(object: any) {
    return JSON.parse(JSON.stringify(object)) as T;
  },
  showMessageToast(
    type = NotifType.success,
    text = t("operation_done_successfully")
  ) {
    if (text == "" && type == NotifType.success) {
      text = t("operation_done_successfully");
    } else if (text == "" && type == NotifType.error) {
      text = t("operation_encountered_error") + "..";
    }
    return ElNotification({
      type: type,
      message: text,
      position: "bottom-left",
      customClass:
        type === NotifType.success ? "nt-message-success" : "nt-message-error",
      duration: 4000,
    });
  },
  getSymbol(data: any, field: string) {
    data = data.map((item) => {
      item[field] = field && item[field] ? item[field] : currency;
      item.symbol =
        item[field] === "USD"
          ? "$"
          : JSON.parse(currencies.value).find(
              (t) => t.currencyCode === item[field]
            )?.symbol;
      return item;
    });
    return data;
  },
  getCurrency() {
    const currencyCode = "USD";
    const currencySymbol = "$";

    return {
      code: currencyCode,
      symbol: currencySymbol,
      title: currencySymbol,
    };
  },
  getCurrencies() {
    return JSON.parse(currencies.value).map((c) => {
      const currencyCode = c.currencyCode;
      const currencySymbol = c.currencyCode === "USD" ? "$" : c.symbol;
      return {
        code: currencyCode,
        symbol: currencySymbol,
        title: currencySymbol,
      };
    });
  },
  getSymbolString(currency: string) {
    const defaultCurrency = JSON.parse(currencies.value).find(
      (c) => c.currencyCode === currency
    );

    const currencySymbol =
      defaultCurrency?.currencyCode === "USD" ? "$" : defaultCurrency?.symbol;

    return currencySymbol;
  },
};

export default utils;
