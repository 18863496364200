export default {
  required_documents: "المستندات المطلوبة",
  email: "البريد الأكتروني",
  offline_payment: "الدفع دون اتصال بالإنترنت",
  payer_name: "اسم الدافع",
  reseller_user_email: "بريد الكتروني وكالت السفر",
  created_by_user_email: "بريد الكتروني مستخدم المسجل",
  created_by_username: "تسجیل المستخدم",
  required: "{label} اجباري",
  min_number: "{label}لا اقل من {min}",
  max_number: "{label} لا اكثر عن {max}",
  max_char: "{label} لا يجب أن يكون أكثر من {max} حرف",
  unspecified_provider_error: "خطأ غير محدد من مجهز التذاکر",
  full_capacity: "لا يوجد مقاعد متوفرة ",
  roles: "الأدوار",
  price_change: "تغییر السعر",
  visa_tourist: "سیاحی",
  visa_business: "تجاری",
  visa_transit: "ترانزيت",
  visa_student: "دراسي",
  visa_employment: "عمل",
  visa_medical: "علاجي",
  compliance_lowest_price: "التزام بالاحد الأدنى للسعر",
  compliance_highest_price: "االتزام بالاحد الأعلى للسعر",
  price: "السعر",
  other_price: "سعر جزئي",
  total_price: "السعر الکلي",
  item_from: "عنصر من ",
  change_price_before_issue: "تغیر السعر قبل الاصدار",
  lack_provider_charge: "الرصيد غير كافي عند المجهز",
  flight_cancellation_by_airline: "إلغاء الرحلة من قبل شركة الطيران",
  delay_adjustment_more_2_hours: "تأخير أو تعديل لمدة تزيد عن ساعتين ",
  personal_reasons: "اسباب شخصية",
  lack_supply: "نقص في تامين",
  from_date: "من تاریخ",
  to_date: "الی تاریخ",
  cancel: "إلغاء",
  tooman: "دولار",
  note_text: "الملاحظات",
  flow_description: "ملاحظات السیستم",
  user_description: "ملاحظات المستخدم",
  submit_note: "تسجیل الملاحظة",
  number_of: "العدد",
  select_provider: "إختار المورد",
  issuance_ticket: "إصدار التذكرة",
  tickets_issued: "التذاکر المصدرة",
  issue_failed: "فشل في إصدار التذکرة",
  issue_in_progress: "جاري الإصدار",
  reissue_succeed: "إعادة إصدار",
  error_finding_user_inventory: "حدث خطأ أثناء استرجاع رصيد محفظة المستخدم",
  confirm_and_submit: "تاکید و تسجیل",
  please_wait: "يرجى الانتظار",
  issue_succeed: "نجاح الإصدار",
  incorrect_pnr: "Incorrect PNR Code",
  reissue_in_Progress: "جاري اعادة الإصدار",
  reissue_fail: "فشل في إعادة الإصدار",
  issue_offline_require: "إصدار افلاین",
  admin_offline_issue: "اصدار التذکره من قبل الادمن آفلاین",
  admin_issue_succeed: "اصدار موفق للتذکرة",
  reasons_failed_issuance: "أسباب عدم الإصدار التذاکر",
  reasons_ticket_refund: "أسباب استرجاع التذكرة",
  number_reserved_tickets: "عدد التذاكر المحجوزة",
  number_tickets_issued: "عدد التذاكر التي تم إصدارها",
  reject_reason: "رفض السبب",
  busy_roads: "الوجهات الأكثر حجزا",
  net_profit: "الربح الصافي",
  basic_information: "البيانات الأساسية",
  description: "الملاحظات",
  inventory_change: "تغيير المخزون",
  current_inventory: "المخزون الفعلي",
  gender: "الجنس",
  flight_filters: "المرشحات",
  user_log: "Logs",
  user_code: "كود العميل",
  reseller_fa_name: "اسم العربي للوكيل",
  reseller_en_name: "الأسم انكليزي للوكيل",
  dollar: "دولار",
  ages: "الأعمار",
  nationality: "الجنسیة",
  type_of_change: "نوع التغيير",
  enter_the_amount: "أدخل المبلغ بال{label}",
  final_total_wallet: "المجموع الكلي للمحفظة",
  enter: "یرجى إدخال {label}",
  password: "كلمة المرور",
  your_password: "كلمة المرور الخاصة بك",
  new_password: "كلمة المرور الجدیدة",
  repeat_new_password: "تکرار كلمة المرور الجدیدة",
  old_password: "كلمة المرور الحالية",
  change_password: "تغییر كلمة المرور",
  recovery_password: "استعادة كلمة المرور",
  forgot_password: "نسيت كلمة المرور",
  one_time_password: "رمز التحقق",
  one_time_password_was_sent: "لقد تم ارسال رمز التحقق",
  one_time_password_message: "لقد تم ارسال رمز التحقق لتغییر کلمة المرور",
  pl_your_emali: "یرجى إدخال البريد إلكتروني",
  all: "الکل",
  charter: "رحلات جارتر",
  system: "رحلات عادية",
  applicable_for_charter: "ينطبق على رحلات  الجارتر",
  user_email: "بريد إلكتروني للمستخدم",
  user_management: "إدارة المستخدم",
  customer_email: "بريد إلكتروني للعميل",
  modifier_email: " البريد الإلكتروني للمحدث البيانات",
  modifier_name: "اسم محدث البيانات",
  port_name: "بوابة الدفع",
  subset_config_name: "اسم مصنف الاعدادات الثانوية",
  sign_in_with_email: "تسجيل الدخول بالبريد الإلكتروني",
  enter_valid_email: "أدخل بريدًا إلكترونيًا صحیحا",
  pl_your_phone_number: "یرجى ادخال رقم الموبایل",
  password_sent_to_number: "لقد تم ارسال کلمة المرور الی رقم الموبايل",
  iata_code: "رقم الأياتا",
  request_code: "کود الطلب",
  code: "کود {label}",
  alternative_iata_code: "رقم الأياتا البديل",
  en_name: "الاسم بالانكليزي",
  eng_name: "الاسم بالانكليزي",
  tr_name: "الاسم بالتركي",
  eng_last_name: "اللقب الانجليزي",
  fa_name: "الاسم بالعربي",
  farsi_name: "الاسم بالفارسی",
  turkey_name: "الاسم بالترکی",
  arabic_name: "الاسم بالعربي",
  fa_last_name: "الاسم الأخير بالعربي",
  alternate_code: "alternate کود",
  before_select_image: "قبل اختيار الصورة",
  country: "الدولة",
  country_name: "اسم البلد",
  destination_country: "بلد الوجهة",
  birth_country: "بلد الولادة",
  passport_issuing_country: "الدولة المصدرة للجواز",
  airport_country: "مطار البلد",
  city: "المدینة",
  origin_city: "مدینة مبدأ",
  destination_city: "مدینة الوجهة",
  airport_city: "مطار المدینة",
  airport_fa_name: "اسم المطار بالفارسي",
  airport_ar_name: "اسم المطار بالعربي",
  airport_en_name: "اسم المطار بلانكليزي",
  airport_iata_code: "رقم الأياتا للمطار",
  airport_time_zone: "تایم زون المطار",
  time_zone: "تایم زون",
  operating_airline_code: "کود شركة طيران Operating",
  marketing_airline_code: "کود شركة طيران Marketing",
  o_airline_code: "کود شركة طيران O",
  m_airline_code: "کود شركة طيران M",
  resend_code: "أعد إرسال کلمة المرور",
  code_is_4_digits: "رمز التحقق مكون من أربعة أرقام",
  reissue: "إعادة إصدار",
  origin: "المبدأ",
  origin_airport: "مطار المبدأ",
  usd_exchange_rate: "سعر الصرف من المبداء (المجهز) (USD)",
  enter_usd_exchange_rate: "یرجى ادخال سعر الصرف من المبداء (المجهز)(USD)",
  enter_as_percentage: "ادخال كانسبه مئويه",
  destination: "الوجهة",
  destination_airport: "مطار الوجهة",
  fare_class: "صنف التسعيرة",
  flight_class: "درجة الطيران",
  load_amount: "وزن الحمولة",
  accesses: "الصلاحیات",
  incomplete_documents: "وثائق غير كاملة",
  sent_documents: "وثائق التی تم ارسالها",
  download_documents: "تحميل الوثائق",
  kilo: "كيلو غرام",
  kilogram: "كيلو غرام",
  to_next: "التالي",
  previous_stage: "المرحلة السابقة",
  next_stage: "المرحلة التالية",
  infant: "رضیع",
  child: "طفل",
  context: "المحتوى",
  rule_text: "قوانين الرحلة",
  number_of_package: "عدد الحقائب",
  optional: "اختیاري",
  cabin: "المقصورة",
  cabin_class: "صنف کابین",
  cabin_load_1: "حمولة الکابین",
  cabin_load: "کمیة حمولة الکابین",
  agency_fa_name: "الإسم العربي للوكيل",
  agency_en_name: "اسم الإنكليزي للوكيل",
  agency_code: "رمز الوكيل",
  agency: "الوكالة",
  agency_name: "اسم الوكالة",
  add_new_agency: "إضافة وکالة جدیدة",
  add_config_new_agency: "إضافة اعدادات جدیدة للوکالة",
  add_new_user: "إضافة مستخدم جدید",
  add_config: "إضافة اعدادات",
  add_subconfig: "إضافة اعدادات ثانوية",
  add_baggage: "اضافة حقائب",
  add_new_rule: "اضافة قوانين جديدة",
  add_detail: "إضافة تفاصیل",
  add_exception: "إضافة استثناءات",
  add: "إضافة",
  add_passenger: "إضافة راكب",
  deposit: "ايداع",
  withdraw: "سحب",
  one: "واحد",
  two: "اثنين",
  three: "ثلاثة",
  four: "اربعة",
  five: "خمسة",
  six: "ستة",
  seven: "سبعة",
  eight: "ثمانية",
  nine: "تسعة",
  ten: "عشرة",
  eleven: "أحد عشر",
  notifications: "إشعارات",
  reserve: "حجز",
  from_hour: "من ساعة",
  to_hour: "الی ساعة",
  flight_hour: "وقت الرحلة",
  remain_day_to_flight: "یوم متبقي للرحلة",
  remain_day_from_flight_hour: "من ساعة n , n یوم متبقي للطیران",
  remain_day_to_flight_hour: "الی ساعة n , n یوم متبقي للطیران",
  remain_hour_to_flight: "ساعة متبقية للطیران",
  to_remain_hour_to_flight: "الی ساعة متبقي للطیران",
  from_remain_hour_to_flight: "من ساعة متبقي للطیران",
  remain_hour_to_flight_from: "من n ساعة متبقية للطیران",
  remain_hour_to_flight_to: "الی n ساعة متبقیة للطیران",
  update_config_for: "تعدیلالاعدادات ل",
  update_airport: "تعدیل المطار",
  order_update_history: "سجل الطلبات",
  select: "{label} اختار",
  select_1: "اختار",
  do_you_want_provider: "هل تريد المجهز ان",
  do_you_want: "هل تريد",
  At_least_one_must_be_selected: "يجب اختيار واحدة على الاقل من ما سبق",
  package: "الحقيبة",
  submit_new_agency: "اضافة وكالة جدیدة",
  chartered_agencies: "وكالات لوكلاء جارتر",
  agencies: "الوكالات",
  airline_en_name: "اسم الانكليزي شركة طيران",
  airline_eng_name: "اسم الأنكليزي شركة طيران",
  airline_fa_name: "اسم العربي لشركة طيران",
  username: "اسم المستخدم",
  reseller_username: "اسم المستخدم للبائع",
  address: "العنوان",
  port_address: "عنوان بوابة الدفع",
  error400: "طلب غیر معتبر",
  error401: "لم يتم احرااز الهوية",
  error403: "صلاحیات غیر معتبرة",
  error404: "لم يتم العثور على الرابط ",
  error500: "خطأ ، يرجى الاتصال بالدعم الفني",
  fare_class_1: "کلاس نرخی(Y,C,K,M,...)",
  start_date: "تاريخ البدء",
  start_time: "وقت البدء",
  end_date: "تاريخ النهاية",
  end_time: "وقت النهاية",
  amount: "مبلغ {label}",
  penalty: "الغرامة",
  provider_penalty_amount: "مبلغ عقوبة المزود",
  provider_refundable_amount: "المبلغ القابل للاسترداد المزود",
  provider_refundable_without_commission: "مبلغ مسترد من المزود بدون عمولة",
  total_amount: "مجموع المبلغ {label}",
  final_amount: "المبلغ النهائي {label}",
  amount_payable_passenger: "المبلغ المدفوع من قبل المسافر",
  dirham: "درهم",
  product: "المنتجات",
  prdouct_tax: "ضريبة المنتج",
  prdouct_markup: "(مقدار الربح)مارکاب المنتج",
  guarantee: "ضمان",
  ticket: "التذکرة",
  port: "بوابة الدفع",
  payable: "المجموع",
  refundable: "قابلة للاسترجاع",
  refund_information: "معلومات استرجاع",
  order: "الطلب",
  commission: "العمولة",
  initial: "اولي",
  discount: "خصم",
  discount_comission: "خصم العمولة",
  base: "اساس",
  additional_services: "خدمات اضافیة",
  penalty_percentage: "نسبة الغرامة",
  add_rules_penalty_percentage: "إضافة قوانين نسبة الغرامة",
  origin_currency: "عملة المبداء",
  destination_currency: "عملة المقصد",
  provider: "المجهز",
  provider_amount: "مبلغ المجهز",
  reference_provider: "مصدر المجهز",
  provider_name: "اسم المجهز",
  provider_code: "رمز المجهز",
  update_provider: "تحديث المجهزين",
  reference_airline: "مصدر شركة الطيران",
  excel_output: "اكسل",
  there_is_no_data: "لا توجد بیانات",
  not_possible_to_issue: "لا يمكن إصدار",
  pl_enter_description: "یرجى ادخال الملاحظات",
  have: "متوفر",
  have_not: "غير متوفر",
  ticket_no: "رقم التذکرة",
  code_sent: "  رمز  الذی تم ارساله",
  ticket_went: "تذكرة ذهاب",
  ticket_return: "تذكرة العودة",
  ticket_issuance_date: "{label} تاریخ اصدار التذکرة",
  from: "من",
  offline_issue: "{label} اصدار افلاين {label_1}",
  ticket_info: "بیانات التذکرة",
  download: "تحميل {label}",
  refunded_ticket: "التذاکر التی تم استرجاعها",
  ticket_warning: "لم یتم ادخال رقم للتذکرة",
  from_time: "منذ",
  new_config_information: "بیانات الاعدادات الجدیدة",
  flight_date: "تاريخ الرحلة {label}",
  aircraft: "نوع الطائرة",
  order_date: "تاریخ الطلب {label}",
  date: "تاریخ {label}",
  application: "تطبيق",
  expiration: "انتهاء الصلاحية",
  passport_expiration_date: "تأريخ نفاد جواز السفر",
  birth: "الولادة",
  birth_date: "تاريخ الميلاد",
  date_of_birth_international: "تاريخ الولادة",
  penalty_start_time: "تاريخ بدایة احتساب الغرامة",
  penalty_end_time: "تاريخ انتهاء احتساب الغرامة",
  register_first_config:
    "بعد حفظ الاعداادات لاول مرة ، لن يكون من الممكن تغيير نوع القائمة وستتمكن بحفظ مزيد من الاعدادات بناءً على نفس نوع القائمة.",
  issue: "إصدار",
  penalty_text: "نصوص الغرامات",
  online_penalty: "غرامة آنلاین",
  baggage_rules: "قوانين الحمولة",
  baggage_amount: "كمية الأمتعة",
  baggage_count: "عدد الأمتعة",
  payment_amount_invalid: "مبلغ الدفع غير صالح",
  user_payment_amount: "مبلغ دفع المستخدم",
  cancel_rules: "قوانين الإلغاء",
  transaction: "الحركة",
  financial_transactions: "الحركات المالية",
  type_of_transactions: "نوع الحركة",
  edit: "تعدیل",
  edit_user_successfully: "تم تعدیل المستخدم بنجاح",
  operation_done_successfully: "تمت المهمة بنجاح.",
  add_new_user_successfully: "تمت إضافة المستخدم بنجاح",
  operation_encountered_error: "حدث خطاء في العملية",
  operation_is_not_possible: " العملية غير ممكنة!",
  edit_user: "تعدیل بيانات المستخدم",
  edit_basic_info: "تعدیل البيانات الاساسية",
  sign_up: "تسجیل",
  change: "تعديل",
  modifier: "المعدل",
  modifier_user: "تغيير المستخدم",
  total: "المجموع",
  total_purchase: "مجموع المشتريات",
  time: "الوقت",
  support: "الدعم",
  history_of_changes: "سجل التغییرات",
  viewـchanges: "عرض التغييرات",
  change_profile_picture: "تغيير صورة الملف الشخصي",
  item_was_found: "تم العثور علیها",
  latest: "الاخير",
  reason: "السبب",
  direct: "مباشر",
  Indirect: "غیر مباشر",
  online: "آنلاین",
  offline: "آفلاین",
  timing: "توقيت",
  app: "تطبیق",
  website: "موقع إلكتروني",
  pwa: "نسخة موقع إلكتروني للجوال",
  economy: "الدرجة السياحية",
  first_class: "الدرجة الأولى",
  business: "درجة رجال الأعمال",
  explain: "اشرح ال",
  refund_reason: "سبب الاسترجاع",
  online_refund: "استرجاع آنلاین",
  refund_in_progress: "جاري عملية الاسترجاع",
  refund_succeeded: "تم الاسترجاع بنجاح",
  refund_failed: "الأسترجاع غير ناجح",
  refund_successful: "تم الأسترجاع",
  payment_and_online_refund: "دفع و استرجاع آنلاین",
  payment_online_manual_refund: "الدفع عبر الإنترنت والاسترجاع يدوي",
  payment_and_manual_refund: "الدفع والاسترجاع يدوي",
  new: "جدید",
  register: "تسجیل",
  new_register: "تسجیل جدید",
  register_new_airport: "تسجيل مطار جديد",
  register_new_config_for: "تسجیل کانفیگ جدید الی",
  not_registered: "غير مسجّل!",
  are_you_sure: "هل أنت متأكد؟",
  route: "مسیر",
  execution: "تنفيذ",
  creation: "إيجاد",
  account_creation_date: "تاریخ إنشاء الحساب",
  account_info: "بیانات حساب المستخدم",
  exit_from_account: "تسجيل الخروج من حساب المستخدم",
  no_information_found: "لم یتم العثور علی بیانات",
  flight: "رحلة طيران",
  passenger: "مسافر",
  payment: "دفع",
  payments: "الحرکات المالیة",
  request: "طلب",
  request_time: "وقت الطلب",
  successfully_requset_message: "تم استلام الطلب بنجاح",
  passengers: "المسافرون",
  recovery: "استعادة",
  adult_baggage: "حمولة البالغ ",
  child_baggage: "حمولة الاطفال ",
  infant_baggage: "حمولة الریضع ",
  deletion_message: "هل انت متأكد من الحذف؟",
  delete: "الحذف",
  succeeded_payment: "عملية الشراء ناجحة",
  yes: "نعم",
  no: "کلا",
  ok: "نعم",
  active: "فعال",
  deactive: "غیرفعال",
  blocked: "محظور",
  active_for_one_way: "فعال لرحلة ذهاب فقط",
  active_for_multi_routes: "فعال للرحلات متعددة المسارات",
  active_for_round_trip: "فعال لرحلات الذهاب والعودة",
  active_for_unpack_round_trip:
    "نشط للرحلات الجوية ذهابا وإيابا ولدیها مقاعد متوفرة ",
  active_for_charter: "فعال للرحلات الجویة جارتر",
  active_for_system: " (العادية)فعال للرحلات الجویة نظامي",
  after_flight_date: "بعد تاريخ الرحلة",
  contract_commission: "عمولة العقد",
  contract: "العقد",
  payment_successful: "تسديد ناجح",
  payment_failed: "تسديد غیر ناجح",
  payed: "مدفوعة",
  not_requested: "غير مطلوب",
  requested_for_offline_payment: "مطلوب للدفع آفلاین",
  in_progress: "في تَقَدم",
  void: "لا عقوبة",
  done: "منتهي",
  back_method: "طريقة العودة",
  payment_in_progress: "جاري التسديد",
  submitted: "مسجل",
  round_trip: "ذهابا وايابا",
  went: "ذهابا",
  next: "التالي",
  previous: "السابق",
  pl_at_least_add_one_item: "الرجاء إضافة عنصر واحد على الأقل",
  reserve_succeeded: "تم الحجز",
  reserve_failed: "فشل الحجز",
  confirm_failed: "غير مؤكد",
  confirm_successful: "مؤكد",
  in_process: "جاري انجاز المهمة",
  failed: "غير ناجح",
  succeeded: "ناجح",
  pending: "بحاجة للتحقق",
  waiting_review: "جاري انتظار التحقق",
  reserve_in_progress: "جاري الحجز",
  login: "تسجيل الدخول",
  number_of_logins: "عدد تسجيلات الدخول",
  number_of_passenger: "عدد المسافرین",
  number_of_purchases: "عدد المشتريات",
  is_percentage: " نسبة مئوية",
  markup: "(مقدار الربح)مارکاب",
  markup_percentage: "نسبة مارکاب (الربح)",
  airplane: "الطائرة",
  tax: "الضريبة",
  amount_commission: "مبلغ العمولة",
  businessـamount_commission: "مبلغ العمولة التجارية",
  discountـamount_commission: "خصم مبلغ العمولة",
  percentage_commission: "نسبة العمولة",
  reseller: "الوكيل",
  resellers: "وکالات السفر",
  resellers_list: "قائمة وکالات السفر",
  resellers_top_up: "قائمة Top-Up",
  register_new_reseller: "إضافة وکالة سفر جدیدة",
  update_reseller: "تعدیل وکالة السفر",
  add_reseller: "إضافة وکالة سفر",
  pl_logo_image: "صورة الشعار من فضلك",
  logo_image: "صورة الشعار",
  returning_arrival_date: "وقت وصول رحلة العودة",
  returning_date: "وقت مغادرة رحلة العودة",
  arrival_date: "تاريخ الوصول",
  departure_arrival_date: "وقت وصول رحلة الذهاب",
  departure_date: "وقت مغادرة رحلة الذهاب",
  order_state: "حالة الطلب",
  issue_date: "وقت الإصدار",
  row: "صف",
  id: "ID {label}",
  class: "کلاس",
  method_execution: "طريقة التطبيق",
  arrival_time: "وقت الوصول",
  maximum_loss: "أقصى خسارة",
  enter_maximum_loss: "أدخل الحد الأقصى للخسارة بال{label}",
  reserve_capability: "إمكانية الحجز",
  config_detail: "تفاصیل الاعدادات",
  baggage: "الحمولة",
  adult: "البالغ",
  state: "{label_1} حالة {label}",
  confirm: "تاکید",
  state_message: "هل أنت متأكد بشأن تغيير الحالة؟",
  holding_infant: "مع رضیع",
  reference_commission: "عمولة المصدر",
  reference: "المصدر",
  do: "فعل؟",
  the: "",
  commission_sharing_coefficient: "نسبة احتساب العمولة",
  surplus_commission_sharing_coefficient: "نسبة احتساب الحمولة الاضافية",
  new_subconfig_info: "تفاصیل الاعدادات الثانوية الجدیدة",
  flight_no: "رقم الرحلة",
  received_commission: "العمولة المستلمة",
  sale_date: "تاریخ البيع {label}",
  departure: "تاریخ الذهاب {label}",
  returning: "تاریخ العودة",
  to: "الی",
  minimum_profit: "الحد الأدنى من الربح",
  ensure_minimum_profit: "الحد الأدنى من الربح المضمون",
  ensuring_minimum_profit: "ضمان الحد الأدنى من الربح",
  enter_minimum_profit: "أدخل الحد الأدنى للربح بال{label}",
  maximum_profit: "الحد الأدنى من الضرر",
  token: "توکن",
  flight_time: "وقت الطيران",
  flight_from_time: "وقت الطیران من",
  flight_to_time: "وقت الطیران الی",
  until_flight_time: "حتى وقت لاحق للرحلة",
  airline: "شرکة الطیران",
  new_number: "رقم جدید",
  national_code: "رقم الهویة الوطنية /الجنسية",
  passenger_national_code: "رقم الهویة الوطنيةللمسافر",
  passenger_type: "نوع الركاب",
  order_code: "رقم الطلب",
  mobile_number_11_digits: "يجب أن يتكون رقم الموبايل من 11 رقمًا",
  mobile: "الموبايل",
  PNR_code: "رقم ال PNR",
  airline_PNR_code: "رقم AirlinePNR",
  passport: "{label} جواز السفر",
  passport_no_passenger: "رقم جواز  المسافر",
  number: "الرقم {label}",
  expiration_time: "تاريخ انتهاء صلاحية الجواز",
  issue_country: "محل الإصدار",
  first_name: "الاسم {label}",
  last_name: "القب {label}",
  first_and_last_name: "اسم و اللقب",
  passenger_name: "اسم المسافر",
  lorem_ipsum: "محتوى غير صحيح و تجريبي",
  ten_days: "عشرة أيام",
  thirty_days: "ثلاثين يومًا",
  sixty_days: "ستون يومًا",
  unknown: "غیرمعلوم",
  canceled: "الانسحاب",
  rejected: "مرفوضة",
  normal: "عادي",
  male: "رجل",
  female: "أنثى",
  quick: "سریع",
  single: "تسجيل الدخول لمرة واحدة",
  multi: "تسجيلات دخول متعددة",
  phone_number: "رقم الموبايل",
  customer_phone_number: "رقم موبايل العميل",
  change_phone_number: "تغییر رقم الموبايل",
  needed_number: "يجب أن يتكون رقم الموبايل من 11 رقمًا",
  needed_amount: "مبلغ  يجب أن لايكون اقل عن 50 دلار",
  amount_not_valid: "المبلغ غير صالح.",
  order_number: "رقم الطلب",
  phone: "رقم الاتصال",
  requset_number: "رقم الطلب",
  sheba_number: "رقم الحساب المصرفي IBN",
  airline_code: "رمز شرکة الطیران",
  airline_name: "اسم شرکة الطیران",
  add_airline: "اضافة شرکة طیران",
  add_new_airline: "اضافة شرکة طیران جدیدة",
  register_new_airline: "تسجيل شركة طيران جديدة",
  update_airline: "تعدیل شرکة طیران",
  airline_marketing: "تسويق شركات الطيران",
  search_desired_airline: "ابحث عن شركة الطيران المطلوبة",
  search: "ابحث",
  close: "لإغلاق",
  orders: "الطلبات",
  manual_order: "تسجيل طب يدوي",
  manual_payment: "الدفع اليدوي",
  manual_registration_orders: "الطلبات المسجلة يدويا",
  airlines: "شرکات الطیران",
  desired_airlines: "شركات الطيران المرغوبة",
  add_config_new_airline: "اضافة اعدادات شرکة طیران جدیدة",
  add_config_new_route: "اضافة اعدادات طرق (روتات )جديدة",
  providers: "المجهزين",
  baggage_setting: "إعدادات الحمولة",
  airports: "المطارات",
  airport: "مطار",
  add_airport: "اضافة مطار",
  white: "أبيض",
  black: "أسود",
  white_list: "القائمة البيضاء",
  black_list: "القائمة السوداء",
  white_list_exception: "استثناء القائمة البيضاء",
  black_list_exception: "استثناء القائمة السوداء",
  subconfig_list: "قائمة الاعدادات الثانوية",
  penalty_rules: "قوانين الغرامات",
  reports_sale: "تقارير المبيعات",
  sale_representative: "مندوب المبيعات",
  sale_channel: "طرق المبيعات",
  start_selling_from: "بدایة البیع من",
  start_selling_to: "بدایة البیع الی",
  total_sale: "مجموع المبیعات",
  reports_passenger: "تقاریر المسافرین",
  reports_ticket: "تقاریر التذاکر",
  route_rules: "قوانين تأشيرات الوجهات",
  pricing: "التسعير",
  flight_filter: "صنّف الرحلات الجوية",
  filter: "صنّف",
  routes_filter: "تصنیف الطرق",
  filter_method: "طريقة التصنیف",
  refund: "استرجاع",
  new_refund_state: "حالة استرجاع جديدة",
  no_new_state_found: "لم يتم العثور على حالة جديدة",
  back_to_home_page: "العودة الی الصفحة الرئیسیة",
  back: "العودة",
  dashboard: "التقاریر البیانیة",
  domestic_flight: "رحلة داخلية",
  international_flight: "رحلة خارجية",
  reports: "تقاریر",
  hotel: "فندق",
  train: "قطار",
  bus: "حافلة",
  tour: "تور",
  villa: "فيلا و سكن",
  customers: "الزبائن",
  visa: "{label} تأشيرة",
  transactions: "العمليات",
  transactions_list: "قائمة العمليات",
  setting: "إلاعدادات",
  advance_setting: "إلاعدادات المتقدمة",
  show_advance_setting: "عرض إلاعدادات المتقدمة",
  currency_rate: "سعر التصریف",
  currency_code: "نوع العملة",
  arrival_terminal: "محطة الوصول",
  departure_terminal: "صالة المغادرة",
  base_fare: "الاجرة الاساسية",
  total_fare: "سعر إلاجمالي للرحلة",
  add_currency_rate: "اضافة سعر تصریف",
  apply_for_approved_rate: "تطبيق السعر الرسمي ",
  register_new_currency_rate: "تسجیل سعر تصریف جدید",
  enter_currency_rate: "أدخل سعر التصریف (دولار)",
  percentage: "نسبة",
  monitoring: "المراقبة",
  extension: "تمدید",
  list: "قائمة",
  currency: "رمز العملة",
  currency_amount: "سعر العملة",
  wallet_inventory: "رصيد المحفظة",
  user_inventory: "رصید المستخدم",
  add_user: "اضافة مستخدم",
  user_has_no_purchases: "المستخدم لم يقم بأي عمليات شراء",
  user_id: "کود المستخدم",
  type: "نوع {label}",
  info: "بیانات {label}",
  complete_user_info:
    "لإكمال معلومات المستخدم ، يرجى متابعة الخطوات بالترتيب .",
  order_detail_info: "بیانات تفاصیل الطلب",
  view_the_flight_information:
    "شاهد معلومات الرحلة في هذا القسم وانتقل إلى الخطوة التالية إذا تم التأكيد",
  view_the_payment_information:
    "راجع معلومات الدفع في هذا القسم، وإذا تمت الموافقة عليها، انتقل إلى الخطوة التالية",
  duration: "فترة زمنية",
  error_info: "البیانات الذی تم ادخالها لیست صحیحة",
  add_new_visa: "إضافة تأشيرة جديدة",
  save: "حفظ",
  add_note: "اضافة ملاحظة",
  note: "ملاحظة",
  notes: "ملاحظات",
  current_state: "الحالة الفعلیة",
  registrar: "المسجل",
  order_detail_info_select: "تم استلام مبلغ التذکرة من قبل المستخدم",
  user_mobile: "رقم هاتف المستخدم",
  order_mobile: "رقم هاتف للطلب",
  discount_code: "كود الخصم",
  charter_system: "الميثاق/النظامي",
  hotel_list: "قائمة الفنادق",
  hotel_info: "معلومات الفندق",
  location: "الموقع",
  province: "المحافظة",
  room: "الغرفة",
  rate: "التقييم",
  users: "المستخدمين",
  user: "المستخدم",
  operation: "العملية",
  hotels: "الفنادق",
  disabled_hotels: "الفنادق الغير مفعلة",
  bank_account_number: "رقم الحساب المصرفي",
  bank_account_info: "بیانات الحساب المصرفي",
  ticket_price: "سعر التذکرة",
  departure_flight: "رحلة ذهاب",
  return_flight: "رحلة عودة",
  creation_time: "وقت الإنشاء",
  flight_to: "الی",
  account_owner_fullname: "اسم و لقب صاحب الحساب",
  add_provider: "افزودن تامین کننده",
  add_new_provider: "افزودن تامین کننده جدید",
  gateway_name: "اسم بوابة الدفع",
  gateway_type_name: "نوع بوابة الدفع",
  add_gateway: "اضافه بوابة دفع",
  gateway_id: "کود بوابة الدفع",
  register_new_gateway: "تسجیل بوابة دفع جدیدة",
  update_gateway: "تعدیل بوابة الدفع",
  gateways: "بوابات الدفع",
  flight_type: "نوع الرحلة",
  PL_ENTER_YOUR_EN_FIRST_NAME:
    "يرجى إدخال الاسم الأول الخاص بك بالحروف اللاتينية",
  PL_ENTER_YOUR_EN_LAST_NAME:
    "يرجى إدخال الاسم الأخير الخاص بك بالحروف اللاتينية",
  PL_ENTER_YOUR_FA_FIRST_NAME:
    "يرجى إدخال الاسم الأول الخاص بك بالحروف العربیة",
  PL_ENTER_YOUR_FA_LAST_NAME:
    "يرجى إدخال الاسم الأخير الخاص بك بالحروف العربیة",
  PHONE_NUMBER_IS_INVALID: "رقم الهاتف غير صالح",
  PHONE_IS_INVALID: "رقم الهاتف الذي تم إدخاله غير صحيح",
  EMAIL_IS_INVALID: "البريد الإلكتروني غير صالح",
  USERNAME_IS_INVALID: "يرجى استخدام الحروف والأرقام الإنجليزية",
  PASSPORT_NO_IS_INVALID: "رقم جواز السفر غير صالح",
  RECEIVING_INFORMATION: "تلقي المعلومات...",
  PL_ENTER_YOUR_NATIONAL_CODE: "الرجاء إدخال رقم الهوية الوطنية الخاص بك",
  NATIONAL_CODE_IS_INVALID: "رقم الهوية الوطنية غير صالح",
  SELECT_THE_DEPARTURE_FLIGHT: "حدد تاريخ المغادرة",
  commercial_phone_number: "رقم الهاتف التجاري",
  service_type: "نوع الخدمة",
  company_contact_info: "معلومات الاتصال بالشركة",
  accounting_phone_number: "رقم هاتف المحاسبة",
  company_owner_info: "معلومات صاحب الشركة",
  agency_acquirer_name: "اسم الوكيل",
  company_loaction_info: "معلومات موقع الشركة",
  company_district: "موقع الشركة",
  agreement_info: " معلومات العقد",
  agreement_file: "ملف العقد",
  select_file: "انتخاب الملف",
  agreement_date: "تاريخ العقد",
  settlement_info: "معلومات المحاسبة",
  settlement_period: "فترة المحاسبة",
  settlement_type: "نوع المحاسبة",
  settlement_start_date: "تاريخ بدأ المحاسبة",
  last_login: "تاریخ تسجيل دخول",
  postpay_balance: "اقل مبلغ",
  prepay_balance: "المبلغ الباقي",
  total_balance: "كل المبلغ ",
  total_issued_tickets: "كل التذاكر الصادرة",
  total_issued_orders: "كل الطلبات الصادرة",
  company_operation_city: "مكان الشركة",
  reseller_Ar_name: "الاسم العربي للبائع",
  reseller_name: "اسم البائع",
  update_state_reseller: "تحديث وضع البائع ",
  none: "هیچ",
  provider_error: "خطأ provider",
  credit_amount: "مبلغ الائتمان",
  internal_error: "خطأ داخلي",
  other: "اخريين",
  agreement_expired: "انتهاء الاتفاقية",
  agreement_canceled: "اللغاء الاتفاقية",
  not_settling: "عدم اكمال الحساب المالي",
  disable_reason: "سبب إيقاف التشغيل",
  receiptNo: "رقم إيصال",
  wallet: "محفظة",
  change_state_reason: "سبب تغير الحالة",
  whats_app_group: "مجموعه واتس اب ",
  general_phone_number: "رقم الجوال العام",
  contacts_info: "معلومات الاتصال",
  panel_info: "معلومات البنل",
  panel_url: "رابط البنل ",
  panel_office_id: "معرف البنل",
  panel_password: "معرف مكتب البنل",
  panel_username: "اسم مستخدم البنل",
  show_link: "عرض الرابط",
  please_type_in_english: "الرجاء إدخال الاسم باللغة الإنجليزية",
  please_type_in_arabic: "الرجاء إدخال الاسم باللغة العربية",
  please_type_in_farsi: "الرجاء إدخال الاسم باللغة الفارسية",
  fare_rules: "قوانین الحجز و الالغاء",
  title: "عنوان",
  fare_rule_category_type: "نوع قوانین الحجز و الالغاء",
  english_description: "ملاحظات الإنجليزية",
  localize_description: "ملاحظات عربیة",
  continue: "متابعة",
  view: "منظر",
  airline_pnr: "طيران PNR",
  flight_travelers_count: "عدد المسافرين على متن الطائرة",
  payment_method: "طريقة الدفع",
  payment_provider_name: "بوابة الدفع",
  first_origin: "المبدأ",
  o_city_name: "مدينة البدأ",
  first_destination: "المقصد",
  d_city_name: "مدينة المقصد",
  first_departure_date: "وقت الحركة",
  first_departure_arrival_date: "وقت الوصول",
  first_flight_type: "نوع الطيران",
  total_discount_of_commission_amount: "إجمالي کمیسیون وکالت السفر",
  total_business_commission_amount: "مبلغ العمولة للسفاری",
  total_commission_amount: "إجمالي الکمیسیون",
  total_base_fare: "إجمالي BaseFare",
  total_extra_services_amount: "إجمالي OtherPrices",
  gateway: "بوابة الدفع",
  admin: "المشرف",
  one_way: "ذهاب",
  multi_destination: "متعدد المسارات",
  show_orders: "عرض الطلبات",
  show_pricing: "عرض التسعير",
  creator_user_email: "البريد الإلكتروني للمبدع",
  main_user_email: "البريد الإلكتروني للبائع",
  wallet_transaction_source_type: "مصدر المعاملة",
  last_admin_topup_deposit_amount: "اخر مبلغ إيداع من المشرفين",
  wallet_balance_amount_before_transaction: "رصيد المحفظة قبل المعاملة",
  wallet_balance_amount_after_transaction: "رصيد المحفظة بعد المعاملة",
  information: "معلومات",
  invalid_costs_entered: "الأسعار المدخلة غير صحيحة",
  factor: "فاتورة",
  deposit_amount: "المبلغ المودع",
  supply_currency_code: "رمز عملة المورد",
  deposit_currency_code: "رمز عملة الايداع ",
  add_top_up: "تعزيز رصيد جديد",
  register_new_top_up: "اضافة تعزيز رصيد",
  pl_factor_file: "الرجاء فايل الفاتورة",
  providers_top_up: "تعزيزات الموردين",
  amount_is_invalid: "الرقم المدخل غير صحيح",
  show_top_ups: "عرض Top-Up",
  limit_file_message:
    "يمكنك تحديد ملف واحد كحد أقصى. بالنسبة للملف الجديد، قم أولاً بحذف الملف السابق.",
  confirm_in_progress: "في انتظار التأكيد",
  refund_rejected: "تم إرجاع المبلغ المسترد",
  system_issue_succeed: "تم إصدار بنجاح بواسطة النظام",
  checkin_date: "تاريخ تسجيل الوصول {label}",
  checkout_date: "تاريخ الخروج {label}",
  hotel_type: "نوع الفندق",
  total_discount_amount: "إجمالي الخصم",
  total_commission: "إجمالي العمولة",
  total_markup: "إجمالي Markup",
  total_tax_amount: "إجمالي الضريبة",
  total_fare_hotel: "إجمالي TotalFare",
  dialing_code: "بادئات البلد",
  top_up: "Top-Up",
  accounting: "محاسبة",
  reports_b2b: "تقارير B2B",
  reports_b2c: "تقارير B2C",
  reports_deposit_withdraw: "تقارير ايداع و سحب",
  reports_receipts: "تقارير إيصالات",
  ticket_provider: "المجهز التذکرة",
  pnr: "رقم المرجع",
  provider_commission_percent: "نسبة عمولة المورد",
  provider_commission_amount: "مبلغ عمولة المورد",
  percent: "نسبه مئويه {label}",
  refunded: "ردها",
  profit: "ربح",
  fee: "الأجر",
  agency_commission_percent: "نسبة عمولة الوكالة",
  agency_commission_amount: "مبلغ عمولة الوكالة",
  amount_to_agency: "المبلغ المدفوع للوكالة",
  amount_sales_refunded: "مبلغ المبيعات المستردة",
  purchasing: "شراء",
  create: "يخلق",
  username_ar: "الاسم العربي للمستخدم",
  creator: "للمبدع",
  BackOffice_Users_View: "عرض مستخدمین البك افيس",
  InternationalFlight: "رحلات دولیة",
  DomesticFlight: "رحلات داخلیة",
  Customers_List_View: "عرض قائمة الزبائن",
  InternationalFlight_Refund_View: "عرض استرجاعات رحلات الخارجیة",
  InternationalFlight_RuleEngine_View: "عرض مرشحات رحلات الخارجیة",
  InternationalFlight_RouteRulePolicy_View: "عرض قوانین تأشيرة الوجهات ",
  InternationalFlight_Reports_View: "عرض تقاریر رحلات الخارجیة ",
  InternationalFlight_Crcn_View: "عرض قوانین الغرامات رحلات الخارجیة",
  InternationalFlight_Airports_View: "عرض مطارات رحلات الخارجیة ",
  update_user: "تعدیل المستخدم",
  register_new_user: "إضافة مستخدم الجدیدة",
  initial_amount: "السعر المبدئي",
  hotel_name: "اسم الفندق",
  type_of_room: "نوع الغرف",
  number_of_rooms: "تعداد الغرف",
  check_in_date: "تاریخ  الدخول",
  check_out_date: "تاریخ الخروج",
  length_of_residence: "مدت الاقامة",
  type_of_service: "نوع الخدمة",
  number_of_passengers: "تعداد المسافرین",
  reservatore: "ريسيرفاتوري",
  reserve_date: "وقت الحجز",
  cancel_succeeded: "نجح الإلغاء",
  cancel_in_progress: "جاري الإلغاء",
  cancel_failed: "الإلغاء غیر ناجح",
  cancel_reject: "تم إرجاع الغاء",
  amenities_list: "قائمة المرافق",
  icon: "أيقونة",
  icon_image: "صورة أيقونة",
  pl_icon_image: "صورة أيقونة من فضلك",
  update_amenity: "تعدیل المرافق",
  register_new_amenity: "إضافة مرافق الجدیدة",
  hotel_manual_order: "تسجیل یدوي فندق",
  ar_name_hotel: "اسم العربي الفندق",
  eng_name_hotel: "اسم الانكليزي الفندق",
  fa_name_hotel: "اسم الفارسي الفندق",
  accommodation_type: "نوع السكن",
  hotel_star: "نجوم الفندق",
  one_star: "نجمة واحدة",
  two_star: "نجمتين",
  three_star: "ثلاثة نجوم",
  four_star: "اربع نجوم",
  five_star: "خمسة نجوم",
  region: "المنطقة",
  places_near_hotel: "الأماكن القريبة من الفندق",
  hotel_amenities: "خدمات الفندق",
  image_gallery: "معرض الصور",
  about_hotel: "عن الفندق",
  accommodation_conditions_and_rules: "الأحكام والشروط",
  refund_rules: "قوانین الغاء الحجز",
  room_delivery_time: "ساعة استلام الغرفة",
  room_vacating_time: "ساعة تسلیم الغرفة",
  website_address: "موقع إلكتروني الفندق",
  name_and_surname_of_hotel_coordinator: "اسم و لقب منسق الحجوزات",
  coordinator_phone: "رقم هاتف المنسق",
  one_case: "طفل مع الخدمة",
  child_with_service: "طفل مع الخدمة",
  child_without_service: "طفل بلا خدمة",
  definition_of_age_groups: "تعريف الفئات العمرية",
  rooms: "الغرف",
  add_rooms: "اضافة الغرف",
  register_hotel: "تسجیل الفندق",
  meterage: "ابعاد الغرفة",
  bed_type: "نوع السریر",
  room_services: "خدمات الغرفة",
  number_of_people: "عدد الأفراد",
  number_of_room: "تعداد الغرف",
  image_upload: "ارفع الصور",
  room_name: "اسم الغرفة",
  room_amenities: "خدمات الغرفة",
  register_room: "تسجیل الغرفة",
  PL_ENTER_YOUR_AR_FIRST_NAME: "یرجا ادخال الاسم و اللقب بالعربي",
  HotelID: "HotelID",
  room_list: "قائمة الغرف",
  RoomID: "RoomID",
  edit_hotel: "تعدیل الفندق",
  delete_hotel: "الحذف الفندق",
  PL_ENTER_IN_NUMBER: "الرجاء إدخال الرقم",
  search_amenities: "البحث عن المرافق",
  search_places_near: "البحث في الأماكن القريبة",
  max_infant_age: "الحد الأقصى لعمر الرضیع",
  max_child_age: "الحد الأقصى لعمر الطفل",
  country_city_region: "الدولة/المدينة/المنطقة",
  country_city: "الدولة/المدينة",
  management_rooms: "إدارة الغرف",
  hotel_image_gallery: "معرض صور الفندق",
  config_and_sub_config: "الاعدادات و الاعدادات الثانوية",
  offline_issue_setting: "إعدادات إصدار افلاین",
  add_config_new_offline_issue: "إضافة اعدادات إصدار افلاین",
  register_new_config_offline_issue: "تسجیل اعدادات إصدار افلاین",
  update_config_offline_issue: "تعدیل اعدادات إصدار افلاین",
  offline_issuance_exception: "استثناء الإصدار افلاین",
  offline_issuance: "إصدار افلاین",
  popular_locations_list: "قائمة الأماكن المهمة",
  show_location: "عرض الموقع",
  register_new_popular_location: "سجيل موقع مهم جديد",
  update_popular_location: "تعدیل موقع المهم",
  latitude: "خط العرض",
  longitude: "خط الطول",
  hotel_location: "موقع الفندق",
  two_decimal_validation: "يجب أن تصل القيمة إلى منزلتين عشريتين",
  markup_8_digit: "مارکاب لا يجب أن يكون أكثر من 8 ارقام",
  agreement_started: "بدأ الاتفاقية",
  negative_error: "يجب ألا تكون القيمة سالبة",
  base_fare_validation: "الاجرة الاساسية أعلى من إجمالي سعر الرحلة",
  summary_orders: "ملخص الطلبات",
  contact_info_email: "بريد إلكتروني",
  user_phone_number: "رقم هاتف المستخدم",
  user_fullname: "اسم و لقب للمستخدم",
  passenger_fullname: "اسم و لقب للمسافر",
  reseller_commission_percent: "نسبة عمولة البائع",
  reseller_commission_amount: "مبلغ عمولة البائع",
  amount_to_reseller: "المبلغ المدفوع البائع",
  amount_to_customer: "المبلغ المدفوع العميل",
  payment_type: "نوع الدفع",
  reseller_user_phone_number: "رقم هاتف البائع",
  current_balance: "المبلغ الفعلي",
  user_type_names: "اسم المستخدم/وكالت السفر",
  discount_commission_percent: "خصم نسبة العمولة",
  discount_commission_amount: "مبلغ خصم العمولة",
  user_refunded: "المعاد للعميل",
  purchasing_safari: "شراء السفاری",
  provided_by_airline: "المقدمة من قبل شركة الطيران",
  time_and_date_departure_flight: "وقت و تاريخ رحلة المغادرة",
  buying_amount: "مبلغ الشراء",
  total_refundable_amount: "إجمالي المبلغ القابل للاسترداد",
  contact_support: "اتّصل بفريق دعم الزبائن",
  cancellation_policy: "لقد قرأت سياسة إلغاء الرحلات الداخلية وأوافق عليها.",
  financial_information: "المعلومات المالية",
  name_must_same_as_username: "يجب أن يكون الاسم واسم المستخدم متطابقين.",
  register_refund: "تسجیل الاسترجاع",
  tracking_number: "رقم تتبع الطلب",
  successful_refund_request: "تم تسجيل طلب الاسترداد بنجاح.",
  request_refund_result:
    "سيتم إبلاغك بنتيجة طلب الاسترداد خلال 24 ساعة عن طريق الاتصال الهاتفي.",
  select_reason_refund: "يرجى اختيار سبب الاسترداد",
  pl_select_passenger: "يرجى اختيار المسافر",
  pl_select_passengers: "يرجى اختيار المسافرین",
  pl_agree_cancellation_policy: "يرجى الموافقة على سياسة الإلغاء",
  account_number_limit: "يجب أن يتكون الرقم من 10 أرقام أو أكثر.",
  transaction_date: "تاریخ الحركة {label}",
  landing_page: "الصفحات المقصودة",
  landing_page_image: "الصورة الرئيسية للصفحة المقصودة",
  faq: "الأسئلة الشائعة",
  question: "سؤال",
  answer: "إجابة",
  add_question: "إضافة سؤال جديد",
  delete_question: "حذف السؤال",
  landing_page_title: "تسجيل معلومات الصفحة المقصودة",
  image: "صورة",
  add_origin_destination: "أضف المبدأ و المقصد",
  section_one_text: "نص الجزء الأول",
  section_two_text: "نص الجزء الثاني",
  add_flight: "إضافة رحلة",
  remove_flight: "حذف الرحلة",
  min_image_count: "الحد الأدنى لعدد الصور هو أربع",
  order_phone_number: "رقم هاتف الطلب",
  tour_list: "الجولات العادية",
  origin_tour: "مبدأ الجولة",
  destination_tour: "وجهة الجولة",
  campaign_start_date: "تاريخ بدء الحملة الإعلانية للجولة",
  campaign_end_date: "تاريخ انتهاء الحملة الإعلانية للجولة",
  minimum_tour_period: "الحد الأدنى لفترة الجولة",
  transport_type: "نوع النقل",
  number_of_accommodation: "عدد مواقع الإقامة",
  tour_coordinator_name: "منسق الجولة",
  state_tour: "حالة الجولة",
  resereved: "محجوز",
  passengers_list: "قائمة الركاب",
  management_trasnport: "إدارة النقل",
  management_accommodations: "إدارة مواقع الإقامة",
  edit_tour: "تعديل الجولة",
  delete_tour: "حذف الجولة",
  normal_tour_manual_order: "تسجيل الجولات العادية",
  base_info_and_features: "المعلومات الأساسية والميزات",
  transportation: "النقل",
  accommodations: "مواقع الإقامة",
  tour_category: "تصنيف الجولة",
  capacity_of_each_order: "سعة كل طلب",
  tour_start_day: "يوم بدء الجولة",
  tour_end_day: "يوم انتهاء الجولة",
  name_and_surname_of_tour_coordinator: "اسم ولقب منسق الجولة",
  day: "يوم",
  person: "شخص",
  other_features: "ميزات أخرى",
  about_tour: "حول الجولة",
  conditions_and_rules: "الشروط والقواعد",
  documents_needed: "المستندات المطلوبة",
  equipment_needed: "المعدات المطلوبة",
  insurance: "التأمين",
  tour_guide: "مرشد الجولة",
  airport_transfer: "نقل المطار",
  add_next_destination_city: "إضافة المدينة الوجهة التالية",
  register_transportation_vehicle: "تسجيل وسيلة النقل",
  ship: "سفينة",
  van: "فان",
  personal_car: "سيارة شخصية",
  taxi: "تاكسي",
  minibus: "حافلة صغيرة",
  vehicle_type: "نوع المركبة",
  company: "الشركة",
  does_not_have: "لا يملك",
  ipg_response: "معلومات بوابة الدفع",
  ipg_log: "لاگ بوابة الدفع",
  notification_sms: "إشعار الرسائل القصيرة",
  cancellation_flight: "إلغاء الرحلة",
  delay_in_flight: "تأخير الرحلة",
  sms: "رسالة قصيرة",
  register_new_time_for_flight: "سجل وقت الرحلة الجديد",
  departure_flight_time: "وقت رحلة المغادرة",
  return_flight_time: "وقت رحلة العودة",
  send: "إرسال",
  log_sent_message: "تقرير عن الرسائل القصيرة المرسلة",
  send_message: "إرسال الرسائل القصيرة",
  message: "رسالة قصيرة",
  get_message_text: "الحصول على نص الرسائل القصيرة",
  message_text: "نص الرسائل القصيرة",
  message_subject: "موضوع الرسائل القصيرة",
  extra_amenities: "مرافق إضافية",
  hotel_capacity: "سعة الفندق",
  add_new_accommodation: "تسجيل الإقامة الجديدة",
  number_of_selected_orders: "عدد الطلبات المختارة",
  sending_message: "هل أنت متأكد من إرسال الرسالة؟",
  sending_message_successfully: "تم إرسال الرسالة بنجاح.",
  sending_message_error: "حدث خطأ أثناء إرسال الرسالة.",
  international_hotel: "فندق الخارجیة",
  domestic_hotel: "فندق داخلیة",
  arrival_date_message: "يجب ألا تتجاوز مدة الرحلة يوم واحد.",
  Wifi: "واي فاي",
  Parking: "موقف سيارات",
  BellBoy: "النادل",
  Medical: "طبي",
  SafeBox: "صندوق الأمان",
  Transfer: "تحويل",
  Exchange: "تبادل",
  Sauna: "ساونا",
  Massage: "تدليك",
  Pool: "حمام سباحة",
  RoomService24H: "خدمة الغرف على مدار 24 ساعة",
  Reception24H: "استقبال 24 ساعة",
  Laundry: "مغسلة",
  CarRent: "استئجار سيارة",
  CoffeeShop: "كوفي شوب",
  Smoke: "كوفي شوب",
  Jacuzzi: "جاكوزي",
  Gym: "الجمنازيوم",
  ConferenceHall: "غرفة المؤتمرات",
  Disabled: "Disabled",
  Shop: "متجر",
  submit_hotel_successfully: "تم التسجيل في الفندق بنجاح.",
  submit_hotel_error: "واجه تسجيل الفندق خطأ.",
  single_bed: "سینگل",
  double: "دابل",
  fa_name_room: "اسم الغرفة فارسي",
  ar_name_room: "الاسم العربي للغرفة",
  eng_name_room: "اسم الغرفة بالانجليزية",
  edit_hotel_successfully: "تم تحرير الفندق بنجاح.",
  edit_hotel_error: "حدث خطأ أثناء تعديل الفندق.",
  submit_room_successfully: "تم تسجيل الغرفة بنجاح.",
  submit_room_error: "حدث خطأ أثناء تسجيل الغرفة.",
  edit_room_successfully: "تم التعديل على الغرفة بنجاح",
  edit_room_error: "حدث خطأ أثناء تعديل الغرفة.",
  edit_room: "تحرير الغرفة",
  new_room: "غرفة جديدة",
  order_list: "قائمة الطلبات",
  visa_list: "قائمة التأشيرات",
  visa_country: "بلد التأشيرة",
  visa_types: "أنواع التأشيرات",
  visa_type: "نوع التأشيرة",
  edit_visa: "تعدیل التأشيرة",
  view_details: "عرض التفاصيل",
  famous_place: "صورة لمكان مشهور في هذا البلد",
  max_size: "الحد الأقصى لحجم الصورة 2Mb",
  length: "مدت {label}",
  validity: "صلاحية",
  operation_process: "یجری عملیات",
  cover_image: "الصورة الرئيسية",
  flag_image: "صورة العلم",
  number_of_request: "عدد الطلب",
  requester: "الطالب",
  initial_offline_issuance_changable:
    "الإصدار آفلاین المبدئي (مع إمكانية تغير الأسعار في المستقبل)",
  change_order_info: "تغيير معلومات الطلب",
  update_order: "تحديث الطلب",
  done_success: "منتهي",
  reports_orders: "تقارير الطلب",
  reports_passengers: "تقارير المسافرين",
  customer_fullname: "اسم العميل",
  total_profit_before_commission: "إجمالي الربح قبل العمولة",
  total_profit_after_commission: "إجمالي الربح بعد العمولة",
  total_profit: "اجمالي الربح",
  PL_ENTER_HOTEL_EN_NAME: "يرجى إدخال الاسم الفندق بالحروف اللاتينية",
  PL_ENTER_HOTEL_FA_NAME: "يرجى إدخال الاسم الفندق بالحروف الفارسیة",
  PL_ENTER_HOTEL_AR_NAME: "يرجى إدخال الاسم الفندق بالحروف العربية",
  PL_ENTER_ROOM_EN_NAME: "يرجى إدخال الاسم الغرفة بالحروف اللاتينية",
  PL_ENTER_ROOM_FA_NAME: "يرجى إدخال الاسم الغرفة بالحروف الفارسیة",
  PL_ENTER_ROOM_AR_NAME: "يرجى إدخال الاسم الغرفة بالحروف العربية",
  minimum_select_departure_or_returning:
    "اختر على الأقل موعدًا واحدًا لرحلة المغادرة أو العودة",
  select_departure_date: "حدد تاريخ المغادرة",
  message_sending_date: "تاريخ ووقت الإرسال",
  flight_date_error: "لقد مر تاريخ الرحلة.",
  queue: "في قائمة انتظار الإرسال",
  sent: "تم إرساله",
  failed_to_send: "فشل فى الارسال",
  adult_price: "سعر البالغين",
  child_price: "سعر الطفل",
  infant_price: "سعر الرضيع",
  APPLY_CHANGES: "تطبيق التغييرات",
  start_tour: "بداية الجولة",
  accommodation: "إقامة",
  minimum_pricing_tour: "سعر كل سيارة أعلى على الأقل 3 أيام لشخص واحد كبير",
  register_tour: "سجل الجولة",
  room_service: "خدمة الغرفة",
  price_reduction_percentage_as_infant: "نسبة تخفيض السعر لكل فئة عمرية الرضیع",
  price_reduction_percentage_as_child: "نسبة تخفيض السعر لكل فئة عمرية الاطفال",
  markup_percent_as_one_day_more_than_n:
    "نسبة مارکاب لكل يوم إضافي يزيد عن N من الأيام",
  markup_percent_as_one_day_more: "نسبة مارکاب لكل يوم إضافي",
  VISA_TITLE: "عنوان التأشيرة",
  VISA_TITLE_EN: "عنوان التأشيرة باللغة الإنجليزية",
  new_item_register: "تسجيل تأشيرة جديدة",
  PLEASE_SELECT_COUNTRY_AND_COUNTRY_IMAGES: "يرجى اختيار البلد والصور",
  show_with_paid_currency: "تظهر مع العملة المدفوعة",
  subject: "موضوع",
  unread: "غير مقروء",
  open: "فتح",
  closed: "مغلق",
  need_reply: "بحاجة للرد",
  ticketing: "إدارة العلاقات مع العملاء",
  created_date: "تاريخ الإنشاء",
  full_name: "الاسم الكامل",
  high: "عالي",
  priority: "أولوية",
  operator_fullname: "اسم و لقب المشغل",
  messages: "الرسائل",
  write_your_message: "اكتب رسالتك",
  attach_file: "إرفاق ملف",
  file_attached: "ملف مرفق",
  file: "ملف",
  qr_code: "رمز الاستجابة السريعة",
  generate_qr_code: "إنشاء رمز الاستجابة السريعة",
  google_auth_code: "رمز مصادقة جوجل",
  change_state: "تغيير الحالة",
  download_file: "تحميل الملف",
  visa_duration: "وقت المعالجة",
  visa_entrance_times: "نوع الدخول",
  visa_expiry_months: "مدة انقضاء التأشيرة",
  visa_issue_days: "الاقامة",
  download_x_docs: "تحميل {x} مستند",
  upload_result: "تحميل النتيجة",
  result: "النتيجة",
  finalization_succeeded: "نجحت النهاية",
  input_file: "ملف الإدخال",
  metabase: "ميتابيز",
  promotions: "العروض الترويجية",
  offline_payment_method: "طريقة الدفع آفلاین",
  office: "مكتب",
  user_address: "عنوان المستخدم",
  show_price: "عرض السعر",
  payment_succeed: "نجح الدفع",
  total_price_without_discount: "السعر الإجمالي بدون خصم",
  max_amount: "الحد الأقصى",
  min_amount: "الحد الأدنى",
  service_charge: "رسوم الخدمة",
  add_new_stop: "إضافة توقف جديد",
  manual_order_issue: "إصدار الطلب يدويا",
};
