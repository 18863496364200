export enum Permission {
  "InternationalFlight:RuleEngine:View" = "InternationalFlight:RuleEngine:View",
  "InternationalFlight:RouteRulePolicy:View" = "InternationalFlight:RouteRulePolicy:View",
  "InternationalFlight:Reports:View" = "InternationalFlight:Reports:View",
  "InternationalFlight:Crcn:View" = "InternationalFlight:Crcn:View",
  "InternationalFlight:Airports:View" = "InternationalFlight:Airports:View",
  "InternationalFlight:Refund:View" = "InternationalFlight:Refund:View",
  "Customers:List:View" = "Customers:List:View",
  "BackOffice:Users:View" = "BackOffice:Users:View",
  "InternationalFlight" = "InternationalFlight",
  "DomesticFlight" = "DomesticFlight",

  "Tickets:GetAll:View" = "Tickets:GetAll:View",
  "FlightConfiguration:Currency:View" = "FlightConfiguration:Currency:View",
  "Finance:FinancialTransaction:View" = "Finance:FinancialTransaction:View",
  "Payment:Gateways:View" = "Payment:Gateways:View",
  "Accounts:Users:View" = "Accounts:Users:View",
  "Accounting:AccountingPassengers:View" = "Accounting:AccountingPassengers:View",
  "Accounting:AccountingsOrders:View" = "Accounting:AccountingsOrders:View",
  "InternationalFlight:Customers:View" = "InternationalFlight:Customers:View",
  "InternationalFlight:TransactionsList:View" = "InternationalFlight:TransactionsList:View",
  "InternationalFlight:TravelAgencies:View" = "InternationalFlight:TravelAgencies:View",
  "InternationalFlight:OfflineIssueSetting:View" = "InternationalFlight:OfflineIssueSetting:View",
  "InternationalFlight:Filters:View" = "InternationalFlight:Filters:View",
  "InternationalFlight:Logs:View" = "InternationalFlight:Logs:View",
  "InternationalFlight:Pricings:View" = "InternationalFlight:Pricings:View",
  "InternationalFlight:DestinationVisaRules:View" = "InternationalFlight:DestinationVisaRules:View",
  "InternationalFlight:TicketReports:View" = "InternationalFlight:TicketReports:View",
  "InternationalFlight:PassengerReports:View" = "InternationalFlight:PassengerReports:View",
  "InternationalFlight:SalesReports:View" = "InternationalFlight:SalesReports:View",
  "InternationalFlight:PenaltyRules:View" = "InternationalFlight:PenaltyRules:View",
  "InternationalFlight:ProviderTopUp:View" = "InternationalFlight:ProviderTopUp:View",
  "InternationalFlight:ManualOrders:View" = "InternationalFlight:ManualOrders:View",
  "InternationalFlight:SummeryOrders:View" = "InternationalFlight:SummeryOrders:View",
  "InternationalFlight:Ordsers:View" = "InternationalFlight:Ordsers:View",
  "InternationalFlight:BaggageConfig:View" = "InternationalFlight:BaggageConfig:View",
  "InternationalFlight:Providers:View" = "InternationalFlight:Providers:View",
  "InternationalFlight:Airlines:View" = "InternationalFlight:Airlines:View",
  "InternationalFlight:Agencies:View" = "InternationalFlight:Agencies:View",
  "InternationalFlight:Orders:CreateManual" = "InternationalFlight:Orders:CreateManual",
  "InternationalFlight:Orders:View" = "InternationalFlight:Orders:View",

  "Visa" = "Visa",
  "Visa:Countries:View" = "Visa:Countries:View",
  "Visa:Orders:View" = "Visa:Orders:View",

  "TravelAgencies" = "TravelAgencies",
  "TravelAgencies:List:View" = "TravelAgencies:List:View",
  "TravelAgencies:Transactions:View" = "TravelAgencies:Transactions:View",
  "TravelAgencies:TopUps:View" = "TravelAgencies:TopUps:View",

  "Promotions" = "Promotions",

  "Hotel" = "Hotel",
  "Hotel:Orders:View" = "Hotel:Orders:View",
  "Hotel:List:View" = "Hotel:List:View",
  "Hotel:Orders:CreateManual" = "Hotel:Orders:CreateManual",
  "Hotel:Providers:View" = "Hotel:Providers:View",
  "Hotel:Pricings:View" = "Hotel:Pricings:View",
}
